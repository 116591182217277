<template>
  <div>
    <section class="section-box section-box--black section-box--first">
      <div class="wrapper">
        <v-main-info :data="mainInfoData" class="main-info--no-visual">
          <transition-group name="small-slide-up">
            <v-tabs
                key="tab"
                id="success-story-tab"
                v-if="allDataLoaded && actualTypes && actualTypes.length"
                :tabs="actualTypes"
                :identifier="'short_name'"
                :current-tab="currentTab"
                class="main-info__tabs"
                @onTabClick="filterStories">

            </v-tabs>
            <pulse-loader key="loader" v-if="!allDataLoaded" :color="loaderOptions.color" :size="loaderOptions.size"></pulse-loader>
          </transition-group>
        </v-main-info>
      </div>
    </section>
    <transition name="small-slide-up">
      <div v-if="allDataLoaded && successStories && successStories.length">
        <v-success-story v-for="story in successStories" :key="story.name" :story="story"></v-success-story>
      </div>
    </transition>
  </div>
</template>

<script>
//Components
import MainInfo from '@/components/layout/Main-info/Main-info'
import SuccessStory from '@/components/layout/Success-stories/Success-story'
import Tabs from "@/components/Tabs/Tabs";
//Plugins
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
//Helpers
import * as api from "@/helpers/Api";

export default {
  name: "Success-stories",
  metaInfo: {
    title: 'ITernal Group – IT vendor for complex software solutions'
  },
  data() {
    return {
      mainInfoData: {
        title: 'ITernal stories <span class="main-info-title__highlighted">that we’re proud of</span>',
        subTitle: 'Look through some successful projects we’ve helped make a reality. And we can share even more with you. Get in touch to learn about our expertise since many of our projects aren’t public.',
      },
      successStories: [],
      allSuccessStories: [],
      types: [],
      storiesLoaded: false,
      typesLoaded: false,
      loaderOptions: {
        size: '15px',
        color: '#FF3200'
      },
      currentTab: '',
      routeUpdated: false
    }
  },
  components: {
    'v-main-info': MainInfo,
    'v-success-story': SuccessStory,
    'v-tabs': Tabs,
    PulseLoader
  },
  computed: {
    actualTypes() {
      return this.types.filter(item => item.active)
    },
    allDataLoaded() {
      return this.typesLoaded && this.storiesLoaded
    }
  },
  methods: {
    async getSuccessStories() {
      await api.getSuccessStories().then(result => {
        if (result) {
          this.allSuccessStories = result.data;
          this.successStories = result.data;
          this.storiesLoaded = true;
        }
      });
    },
    async getSystemTypes() {
      await api.getSystemTypes().then(result => {
        if (result) {
          this.types = result.data;
          this.typesLoaded = true;
        }
      });
    },
    findCurrentFilter(value, type) {
      return this.types.find(item => item[type] === value)
    },
    filtering(slug) {
      this.successStories = this.allSuccessStories.filter(story => {
        return story.system_types.some(type => type.slug === slug)
      })
    },
    filterStories(value) {
      if (value === 'All') {
        this.successStories = this.allSuccessStories;
        this.$router.push({name: 'Success-stories'});
      } else {
        const typeSlug = this.findCurrentFilter(value,'short_name').slug;
        this.filtering(typeSlug);
        this.$router.push({name: 'Success-story', params: { slug: typeSlug }}).catch(()=> {});
      }
    },
    setCurrentTab(slug) {
      if (slug) {
        const currentFilterItem = this.findCurrentFilter(slug, 'slug');
        if (currentFilterItem) {
          this.currentTab = currentFilterItem.short_name;
        } else {
          this.currentTab = 'All'
        }
      }
    },
    setData() {
      const slug = this.$route.params.slug;
      if (slug && this.allDataLoaded) {
        if (this.findCurrentFilter(slug, 'slug')) {
          this.filtering(slug);
          this.setCurrentTab(slug);
        } else {
          this.$router.push({name: 'PageNotFound'}).catch(() => {})
        }
      }
    }
  },
  created() {
    this.getSystemTypes();
    this.getSuccessStories().then(() => {
      this.setData()
    });
  },
  watch: {
    $route: 'setData'
  }
}
</script>

<style lang="scss">
.main-info {
  .v-spinner {
    padding-top: 115px;
    text-align: center;

    @media (max-width: $viewport-tablet) {
      padding-top: 80px;
    }
  }
}

.main-info__tabs {
  margin-top: 50px;
}
</style>
