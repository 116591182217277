<template>
  <div class="success-story" :style="{'background': story.background_colors}">
    <div class="wrapper">
      <div class="success-story__wrapper">
        <div class="success-story__info success-story-info">
          <p class="success-story-info__title">{{ story.name }}</p>
          <div class="success-story-info__description" v-html="story.short_description"></div>
          <ul class="success-story-info__tag-list success-story-info-tag-list">
            <li class="success-story-info-tag-list__item" v-for="type in story.system_types" :key="type.system_types">
              <router-link v-if="type.active" :to="{name: 'Success-story', params: {slug: type.slug}}" class="success-story-info-tag-list__link">{{ type.short_name }}</router-link>
              <span v-else class="success-story-info-tag-list__link success-story-info-tag-list__link--disabled">{{ type.short_name }}</span>
            </li>
          </ul>
          <a :href="story.link" v-if="story.link" rel="nofollow" target="_blank" class="success-story-info__external-link">{{ shortLink }}</a>
        </div>
        <div class="success-story__image success-story-image">
          <picture>
            <source media="(min-width: 1025px)" :srcset="getImage(previewPicture.desktop, previewPicture.desktop_retina)">
            <source media="(max-width: 1024px)" :srcset="getImage(previewPicture.tablet, previewPicture.tablet_retina)">
            <img :src="previewPicture.desktop_retina" :alt="story.name" class="success-story-image__item">
          </picture>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Helper from "@/helpers/Helper";
export default {
  name: "Success-story",
  props: {
    story: Object
  },
  computed: {
    shortLink() {
      return this.story.link ? this.domainFromUrl(this.story.link) : false
    },
    previewPicture() {
      return Helper.objectNotEmpty(this.story) && Helper.objectNotEmpty(this.story.preview_picture_versions) ? this.story.preview_picture_versions : false
    },
  },
  methods: {
    domainFromUrl(url) {
      let hostName = new URL(url).hostname;
      let domain = hostName;
      if (hostName != null) {
        let str = hostName.split('.').reverse();
        if (str != null && str.length > 1) {
          domain = str[1] + '.' + str[0];
          if ((hostName.indexOf(/[^/]+((?=\/)|$)/g) !== -2) && str.length > 2) {
            domain = str[2] + '.' + domain;
          }
        }
      }
      return domain;
    },
    getImage(image, retinaImage) {
      return `${image} 1x, ${retinaImage} 2x`
    }
  }
}
</script>

<style lang="scss">
.success-story {
  padding: 90px 0 0;

  @media (max-width: $viewport-mobile) {
    padding: 50px 0 0;
  }
}

.success-story__wrapper {
  display: flex;
  justify-content: space-between;
  min-height: 650px;

  @media (max-width: $viewport-mobile) {
    min-height: auto;
    flex-wrap: wrap;
  }
}

.success-story__info {
  width: 35%;
  padding: 0 90px 70px 0;

  @media (--viewport-tablet) {
    width: 45%;
    padding: 0 50px 70px 0;
  }

  @media (--viewport-tablet-small) {
    width: 50%;
    padding: 0 30px 70px 0;
  }

  @media (max-width: $viewport-mobile) {
    width: 100%;
    padding: 0 0 40px 0;
  }
}

.success-story__image {
  width: calc(65% - 90px);
  box-sizing: border-box;

  @media (--viewport-tablet) {
    width: 55%;
  }

  @media (--viewport-tablet-small) {
    width: 50%;
  }

  @media (max-width: $viewport-mobile) {
    width: 100%;
  }
}

.success-story-image {
  position: relative;
  max-width: 100%;
  min-height: 100%;
  overflow: hidden;

  @media (max-width: $viewport-tablet) {
    left: 15px;
  }

  @media (max-width: $viewport-mobile) {
    left: 0;
    padding-bottom: 60%;
  }
}

.success-story-image__item {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  max-width: 100%;

  @media (max-width: $viewport-desktop) {

  }

  @media (max-width: $viewport-tablet) {
    width: 600px;
    max-width: none;
  }

  @media (max-width: $viewport-tablet-small) {
  }

  @media (max-width: $viewport-mobile) {
    max-width: 100%;
  }
}

.success-story-info__title {
  margin-bottom: 35px;
  color: #FFFFFF;
  font-family: Visuelt Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 2.6875rem;
  line-height: 1.534883721;

  @media (max-width: $viewport-tablet) {
    line-height: 1.4;
  }

  @media (max-width: $viewport-tablet-small) {
    font-size: 2rem;
  }

  @media (max-width: $viewport-mobile) {
    margin-bottom: 25px;
  }
}

.success-story-info__description {
  margin-bottom: 40px;
  color: #FFFFFF;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 2.714285714;

  @media (max-width: $viewport-tablet) {
    line-height: 2.1;
  }

  @media (max-width: $viewport-mobile) {
    margin-bottom: 30px;
  }
}

.success-story-info__tag-list {
  margin-bottom: 30px;
}

.success-story-info__external-link {
  position: relative;
  margin-left: 40px;
  font-family: Visuelt Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 1.3125rem;
  line-height: 1.285714286;
  color: #F8DB7A;
  border-bottom: 1px solid transparent;
  transition: border-bottom-color .2s ease-in-out;

  @media (max-width: $viewport-mobile) {
    margin-left: 35px;
    font-size: 1.125rem;
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: -40px;
    width: 22px;
    height: 22px;
    background: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 22'%3E%3Cpath d='M19.167 19.167H2.833V2.833H11V.5H2.833A2.333 2.333 0 00.5 2.833v16.334A2.333 2.333 0 002.833 21.5h16.334a2.333 2.333 0 002.333-2.333V11h-2.333v8.167zM13.333.5v2.333h4.183L6.048 14.302l1.65 1.65L19.167 4.485v4.183H21.5V.5h-8.167z' fill='%23F8DB7A'/%3E%3C/svg%3E") no-repeat;
    transform: translateY(-50%);

    @media (max-width: $viewport-mobile) {
      left: -35px;
      width: 18px;
      height: 18px;
    }
  }

  &:hover {
    border-bottom-color: #F8DB7A;
  }
}


.success-story-info-tag-list {
  display: flex;
  flex-wrap: wrap;
}

.success-story-info-tag-list__link {
  display: inline-block;
  padding: 10px 25px;
  color: #F8DB7A;
  font-family: Visuelt Pro;
  font-weight: 500;
  font-size: 0.9375rem;
  background-color: rgba(255,255,255,.15);
  margin-right: 10px;
  margin-bottom: 10px;
  transition: background-color .2s ease-in-out;

  @media (max-width: $viewport-tablet-small) {
    padding: 8px 15px 7px;
    font-size: 0.875rem;
  }

  @media (max-width: $viewport-mobile) {
    font-size: 0.875rem;
  }

  &:hover {
    background-color: rgba(255,255,255,.25);
  }

  &--disabled {
    &:hover {
      background-color: rgba(255,255,255,.15);
    }
  }
}
</style>
