<template>
  <ul class="tabs">
    <li class="tabs__item"
        @click.prevent="onTabClick('All')"
        :class="[{'tabs__item--current': localCurrentTab === 'All'}, tabItemClass]">
      <span class="tabs__item-text">All</span>
    </li>
    <li class="tabs__item"
        v-for="tab in tabs"
        @click.prevent="onTabClick(apiName(tab))"
        :key="apiName(tab)"
        :class="[{'tabs__item--current': localCurrentTab === apiName(tab)}, tabItemClass]">
      <span class="tabs__item-text">{{ apiName(tab) }}</span>
    </li>
  </ul>
</template>

<script>
export default {
  name: "Tabs",
  props: {
    tabs: Array,
    tabItemClass: {
      type: String,
      default: ''
    },
    identifier: {
      type: String,
      default: 'name'
    },
    currentTab: {
      type: String,
      default: 'All'
    }
  },
  data() {
    return {
      localCurrentTab: 'All'
    }
  },
  methods: {
    onTabClick(value) {
      this.localCurrentTab = value;
      this.$emit('onTabClick', value);
    },
    apiName(tab) {
      return this.identifier ? tab[this.identifier] : tab.name
    }
  },
  watch: {
    currentTab(value) {
      this.localCurrentTab = value
    }
  }
}
</script>

<style lang="scss">
.tabs {
  display: flex;
  flex-wrap: wrap;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */
  &::-webkit-scrollbar {
    width: 0;
    background: transparent; /* Chrome/Safari/Webkit */
  }

  @media(max-width: $viewport-tablet-small) {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
}
.tabs__item {
  flex-shrink: 0;
  margin-right: 1px;
  margin-bottom: 1px;
  cursor: pointer;

  &:hover {
    .tabs__item-text {
      background-color: #fff;
    }
  }

  &--current {
    .tabs__item-text {
      color: #000;
      background-color: #fff;
    }
  }
}
.tabs__item-text {
  display: block;
  padding: 25px 20px;
  font-family: Visuelt Pro;
  font-size: 0.9375rem;
  color: #707070;
  line-height: 1.266666667;
  background-color: #ececec;
  transition: background-color 0.2s ease-in-out;
}

.section-box--black {
  .tabs__item-text {
    background-color: rgba(#fff, 0.05);
    color: #fff;
  }
  .tabs__item {
    &:hover {
      .tabs__item-text {
        background-color: rgba(#fff, 0.2);
      }
    }
    &--current {
      .tabs__item-text {
        background-color: rgba(#fff, 0.2);
        color: #F8DB7A;
      }
    }
  }
}
</style>
